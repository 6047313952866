import React, { Fragment } from 'react'
import Main from './components/Main'
import './scss/App.scss'

function App() {
	return (
		<Fragment>
			<Main />
		</Fragment>
	)
}

export default App
